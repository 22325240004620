export const SETTINGS_MENU_KEYS = {
  LOCATION_GENERAL: "location-general",
  ADDRESS: "address",
  TEXT_MESSAGES: "text-messages",
  SELF_CHECK_IN: "self-check-in",
  CATEGORIES: "categories",
  RESERVATIONS: "reservations",
  INPUT_FIELDS: "input-fields",
  ACCOUNT_GENERAL: "account-general",
  APPEARANCE: "appearance",
  AREAS: "ares",
  USERS: "users",
  INVITES: "invites",
  REGIONS: "regions",
  ADVANCED: "advanced",
  WIDGETS: "widgets",
  CONNECT_TO_SQUARE: "connect-to-square"
};

export const SETTINGS_MENU = [
  {
    name: "Location Settings",
    children: [
      {
        name: "General",
        key: SETTINGS_MENU_KEYS.LOCATION_GENERAL
      },
      {
        name: "Address",
        key: SETTINGS_MENU_KEYS.ADDRESS
      },
      {
        name: "Text messages",
        key: SETTINGS_MENU_KEYS.TEXT_MESSAGES
      },
      {
        name: "Self Check-in",
        key: SETTINGS_MENU_KEYS.SELF_CHECK_IN
      },
      {
        name: "Guest Preferences",
        key: SETTINGS_MENU_KEYS.CATEGORIES
      },
      {
        name: "Reservations",
        key: SETTINGS_MENU_KEYS.RESERVATIONS
      },
      {
        name: "Input Fields",
        key: SETTINGS_MENU_KEYS.INPUT_FIELDS
      },
      {
        name: "Advanced",
        key: SETTINGS_MENU_KEYS.ADVANCED
      }
    ]
  },
  {
    name: "Account Settings",
    children: [
      {
        name: "General",
        key: SETTINGS_MENU_KEYS.ACCOUNT_GENERAL
      },
      {
        name: "Appearance",
        key: SETTINGS_MENU_KEYS.APPEARANCE
      },
      {
        name: "Users",
        key: SETTINGS_MENU_KEYS.USERS
      },
      {
        name: "User Invites",
        key: SETTINGS_MENU_KEYS.INVITES
      },
      {
        name: "Regions",
        key: SETTINGS_MENU_KEYS.REGIONS
      },
      {
        name: "Areas",
        key: SETTINGS_MENU_KEYS.AREAS
      },
      {
        name: "Connect to Square",
        key: SETTINGS_MENU_KEYS.CONNECT_TO_SQUARE
      },
      {
        name: "Website Widgets",
        key: SETTINGS_MENU_KEYS.WIDGETS
      }
    ]
  }
];

export const ROLE_KEYS = {
  USER: "user",
  MANAGER: "manager",
  AREA_MANAGER: "area-manager",
  REGIONAL_MANAGER: "regional-manager",
  ANALYST: "analyst",
  ADMIN: "admin"
};

export const ROlES = [
  {
    name: "Shared User",
    role: ROLE_KEYS.USER,
    permissions: ["Best for share devices", "Add/Edit guests", "Notify guests", "Cannot change settings"]
  },
  {
    name: "Manager",
    role: ROLE_KEYS.MANAGER,
    permissions: ["Add/Edit guests", "Notify guests", "Change settings for their location"]
  },
  {
    name: "Area Manager",
    role: ROLE_KEYS.AREA_MANAGER,
    permissions: [
      "Add/Edit guests",
      "Notify guests",
      "Change settings in their area",
      "View/Edit waitlists in their area",
      "View dashboard"
    ]
  },
  {
    name: "Regional Manager",
    role: ROLE_KEYS.REGIONAL_MANAGER,
    permissions: [
      "Add/Edit guests",
      "Notify guests",
      "Change settings in their region",
      "View/Edit waitlists in their region",
      "View dashboard"
    ]
  },
  {
    name: "Analyst",
    role: ROLE_KEYS.ANALYST,
    permissions: ["Add/Edit guests", "Notify guests", "View dashboard"]
  },
  {
    name: "Admin",
    role: ROLE_KEYS.ADMIN,
    permissions: [
      "Add/Edit guests",
      "Notify guests",
      "Change settings all locations",
      "View/Edit waitlist for all locations",
      "View dashboard",
      "Add/Edit users",
      "Update account settings",
      "Update billing information"
    ]
  }
];
