export const displayDashboard = (plan, user) => {
  return (
    (plan === "enterprise" || plan === "pro") &&
    (user.claims.role === "admin" ||
      user.claims.role === "regional-manager" ||
      user.claims.role === "area-manager" ||
      user.claims.role === "analyst")
  );
};

export const displayCharts = () => {
  return true;
};

export const displayCustomers = (plan, user, account) => {
  return (plan === "enterprise" || plan === "pro" || plan === "premium") && userIsAdmin(user) && !isBigAccount(account);
};

export const showOnDashboard = (plan, user) => {
  return userIsAdmin(user) && (plan === "enterprise" || plan === "pro");
};

export const userIsAdmin = user => {
  if (user) {
    return user.claims.role === "admin";
  }
  return false;
};

export const hideSettings = (user, account) => {
  return (
    user.claims.role === "user" ||
    user.claims.role === "analyst" ||
    (account?.settings?.adminsOnly && user.claims.role !== "admin")
  );
};

export const isBillingUser = (user, account) => {
  if (!user || !account) {
    return false;
  }

  return user.uid === account.billingUserId;
};

export const isUpgradable = account => {
  if (account) {
    return account.plan !== "enterprise" && (account.plan !== "pro" || account.autoPay === false);
  }
  return true;
};

export const isBigAccount = account => {
  //is trulieve
  return ["xj5spEZSQb2wd0nFRKY6", "IgBNLUJQqJwa1URhwUAX"].includes(account.id);
};

export const canAddLocations = (account, user, businesses) => {
  return (
    user?.claims?.role === "admin" &&
    account &&
    (account.plan === "enterprise" || account.plan === "pro") &&
    account.settings &&
    businesses &&
    businesses.length < account.settings.maxLocations
  );
};
