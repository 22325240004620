import { useEffect, useState } from "react";
import Select from "react-select";
import "./WaitlistOrder.scss";

const customStyles = {
  container: (_, { selectProps: { width } }) => ({
    width,
    marginRight: "14px"
  }),
  singleValue: styles => ({
    ...styles,
    fontSize: "14px"
  }),
  option: styles => ({
    ...styles,
    fontSize: "14px"
  })
};

const options = [
  {
    value: "check-in",
    label: "Check-in Time"
  },
  {
    value: "priority",
    label: "Priority"
  },
  {
    value: "time-left",
    label: "Time Left"
  }
];

const WaitlistOrder = ({ isEdit, listOrder, setListOrder }) => {
  useEffect(() => {
    if (!listOrder) {
      setListOrder(options[0]);
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      setListOrder(options[1]);
    }
  }, [isEdit]);

  return (
    <div className="wl-order">
      <div className="wl-order__row">
        <Select
          onChange={setListOrder}
          styles={customStyles}
          value={listOrder}
          options={options}
          width="160px"
          isSearchable={false}
        />
      </div>
    </div>
  );
};

export default WaitlistOrder;
