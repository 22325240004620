import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import "./waitlistTable.scss";
import { setNewWaitlistPriorities, VisitStatus } from "services/visits/visits.service";
import { addOrUpdateVisit } from "services/visits/visits.service";
import moment from "moment";
import WaitlistFilter from "components/WailtlistFilter/WaitlistFilter";
import { logEvent } from "services/firebase/firebase.service";
import { markMessagesAsRead } from "services/messages/messages.service";
import { FIREBASE_EVENTS } from "constants/firebase";
import StayPrimaryPortraitIcon from "@material-ui/icons/StayPrimaryPortrait";
import Badge from "@material-ui/core/Badge";
import ChatModal from "components/Chat/Chat";
import { getTimeDifferenceInMinutes } from "services/helpers/waitlist";

import {
  notifyErrorAlert,
  noPhoneNumAlert,
  spamAlert,
  autoNofications,
  invalidNumber,
  optOut,
  noEmailAlert,
  notifyGuestEmailAlert,
  notifyPartyAlert
} from "constants/messages";
import { MESSAGE_TYPES } from "constants/text-messages";
import TimerTick from "common/TimerTick/TimerTick";
import { limitReachedNotification } from "constants/messages";
import WarningIcon from "@material-ui/icons/Warning";
import { connect, useSelector } from "react-redux";
import { isNotEnterprise } from "services/plans/plans.helper";
import { deleteMessage } from "constants/messages";
import { PHONE_CONFIG_STATUSES } from "constants/verification";
import { VERIFICATION_MODES } from "constants/verification";
import { isGracePeriodAfterNow } from "constants/verification";
import cx from "classnames";
import { getPreferenceColorByIndex } from "helpers/preference.helper";
import { useMediaQuery } from "hooks/useMediaQuery";
import { WaitlistAdditionalActionsButton } from "components/WaitlistTable/WaitlistAdditionalActionsButton";
import dragIndicator from "assets/img/DragIndicator.png";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import WaitlistOrder from "components/WaitlistOrder/WaitlistOrder";
import { DateTime } from "luxon";

const DragIndicator = () => {
  return <img src={dragIndicator} style={{ height: 20 }} />;
};

const WaitlistTableSortableItemWrapper = props => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, isDragging } = useSortable({
    id: props.id,
    animateLayoutChanges: () => false,
    strategy: verticalListSortingStrategy
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative"
  };

  return (
    <tr ref={setNodeRef} style={style}>
      {props.isEdit && (
        <td>
          <button ref={setActivatorNodeRef} {...attributes} {...listeners} className="drag-btn">
            <DragIndicator />
          </button>
        </td>
      )}
      {props.children}
    </tr>
  );
};

const ButtonHistoryRestore = ({ disabled, onClick }) => {
  return (
    <div className="wl-table__actions-container">
      <span onClick={disabled ? () => null : onClick}>
        <i
          className={cx({
            "mdi mdi-delete-restore wl-table__action-icon wl-table__action-icon--restore": true,
            "wl-table__action-icon--disabled": disabled
          })}
        >
          <span className="badge-label">Restore</span>
        </i>
      </span>
    </div>
  );
};

const ButtonsWaitlist = ({
  isChatEnabledForAccount,
  item,
  shouldBeDisabled,
  getMessagesCount,
  openChatModal,
  disabled,
  notifyPartyAgain,
  promptNotification,
  seatUserClick,
  allowDelete,
  business,
  editVisitClick,
  walkoutVisitClick,
  removeVisitClick,
  addNoteClick,
  isMobile = false
}) => {
  const allowOMS = (business, item) => {
    return business?.settings?.advanced?.omsIntegration || false;
  };

  const allowLobby = business => {
    return business?.settings?.advanced?.lobbyFeature || false;
  };

  const generateCustomerUrl = (visit, business) => {
    //seach for '{customerNumber}' in  business.settings.advanced.customerUrl and replace with visit.data().customerNumber
    let url = business?.settings?.advanced?.profileUrl;
    if (url && visit.data().customerNumber) {
      url = url.replace("{customerNumber}", visit.data().customerNumber);
    }
    return url;
  };

  const omsClick = item => {
    window.open(generateCustomerUrl(item, business), "_blank");
  };

  const lobbyClick = item => {
    console.log("lobbyStatus: ", item.data().lobbyStatus, !(item.lobbyStatus || false));
    addOrUpdateVisit({
      id: item.id,
      lobbyStatus: !(item.data().lobbyStatus || false),
      businessId: business.businessId
    });
  };

  return (
    <div className="wl-table__actions-container">
      {isChatEnabledForAccount() ? (
        <span className="badge-wrapper">
          {!shouldBeDisabled(item) ? (
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              badgeContent={getMessagesCount(item.id)}
              color="error"
            >
              <StayPrimaryPortraitIcon
                onClick={openChatModal.bind(this, item)}
                style={{
                  fontSize: isMobile ? "34px" : "50px",
                  color: "#000",
                  cursor: "pointer"
                }}
              />
              {item.data().errorCode && <WarningIcon className="warning-icon" />}
            </Badge>
          ) : (
            <StayPrimaryPortraitIcon
              style={{
                fontSize: isMobile ? "34px" : "50px",
                color: "#9b9a9a",
                cursor: "not-allowed"
              }}
            />
          )}
          <i>
            <span
              className="badge-label"
              style={{
                color: shouldBeDisabled(item) ? "#9b9a9a" : "#000",
                cursor: shouldBeDisabled(item) ? "not-allowed" : "pointer"
              }}
              onClick={shouldBeDisabled(item) ? () => null : openChatModal.bind(this, item)}
            >
              Chat
            </span>
          </i>
        </span>
      ) : null}

      {item.data().firstNotifiedTime && !item.data().errorCode ? (
        <span className="wl-table__outline-msg-box" onClick={disabled ? () => null : notifyPartyAgain.bind(this, item)}>
          <span className="chat-badge-wrapper">
            <Badge badgeContent={item.data().numNotifications} color="primary">
              <i
                className={cx({
                  "mdi mdi-message-outline wl-table__action-icon": true,
                  "wl-table__action-icon--chat-enabled": !disabled,
                  "wl-table__action-icon--disabled": disabled
                })}
              >
                <span className="badge-label">Notify</span>
              </i>

              <span className="wl-table__outlined-msg">
                <TimerTick date={item.data().firstNotifiedTime.toDate()}></TimerTick>
              </span>
            </Badge>
          </span>
        </span>
      ) : (
        <span onClick={shouldBeDisabled(item) ? () => null : promptNotification.bind(this, item)}>
          <i
            className={cx({
              "mdi mdi-message-text-outline wl-table__action-icon": true,
              "wl-table__action-icon--chat-enabled": !shouldBeDisabled(item) && !item.data().errorCode,
              "wl-table__action-icon--chat-disabled wl-table__action-icon--disabled":
                shouldBeDisabled(item) || item.data().errorCode
            })}
          >
            <span className="badge-label">Notify</span>
          </i>
        </span>
      )}
      <span>
        <i
          onClick={disabled ? () => null : seatUserClick.bind(this, item)}
          className={cx({
            "mdi mdi-check-box-outline wl-table__action-icon  wl-table__action-icon--check": true,
            "wl-table__action-icon--disabled": disabled
          })}
        >
          <span className="badge-label">Done</span>
        </i>
      </span>

      <WaitlistAdditionalActionsButton
        disabled={disabled}
        isDeleteButtonVisible={allowDelete(business)}
        isLobbyVisible={allowLobby(business)}
        isOMSVisible={allowOMS(business)}
        item={item}
        editVisitClick={editVisitClick}
        walkoutVisitClick={walkoutVisitClick}
        removeVisitClick={removeVisitClick}
        addNoteClick={addNoteClick}
        omsClick={omsClick}
        lobbyClick={lobbyClick}
        isMobile={isMobile}
      />
    </div>
  );
};

function WaitlistTable(props) {
  const {
    account,
    business,
    guestList,
    removeVisit,
    addNote,
    editVisit,
    walkoutVisit,
    seatUser,
    notifyGuest,
    sendMessage,
    restoreVisitorToWaitlist,
    history,
    user,
    filter,
    setFilter,
    listOrder,
    setListOrder,
    disabled: _disabled
  } = props;
  const isAnalyst = user.claims.role === "analyst";
  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [visitToDelete, setVisitToDelete] = useState(null);
  const [infoAlert, setInfoAlert] = useState(null);
  const [limitMessage, setLimitMessage] = useState(null);
  const [notificationLimit, setNotificationLimit] = useState(null);
  const [limitReached, setLimitReached] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [filteredGuestList, setFilteredGuestList] = useState([]);
  const [chat, setChat] = useState(null);
  const [waitTimeLastUpdated, setWaitTimeLastUpdated] = useState(false);
  const [verifyAlert, setVerifyAlert] = useState(false);
  const [deleteVisitMessage, setDeleteVisitMessage] = useState(null);
  const { verificationMode } = useSelector(state => state.config || {});
  const [isPriorityOrdering, setIsPriorityOrdering] = useState(false);

  const disabled = _disabled || isAnalyst || isPriorityOrdering;

  const isTablet = useMediaQuery("(max-width: 1300px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (isMobile && isPriorityOrdering) {
      setIsPriorityOrdering(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (visitToDelete) setDeleteVisitMessage(deleteMessage("Guest", visitToDelete.data().name));
  }, [visitToDelete]);

  useEffect(() => {
    if (account) {
      setNotificationLimit(account.notificationLimit);
      const visitCount = account.visitCount;
      if (account.notificationLimit < visitCount && isNotEnterprise(account.plan)) {
        if (account.notificationLimit + 1 === visitCount) {
          setLimitMessage(limitReachedNotification);
        }
        setLimitReached(true);
      }
    }
  }, [account]);

  useEffect(() => {
    if (guestList && guestList.length) {
      let list = guestList;
      if (filter.partySize) {
        const values = filter.partySize.value.split("-");
        if (values.length === 2) {
          list = list.filter(
            val => val.data().partySize >= Number(values[0]) && val.data().partySize <= Number(values[1])
          );
        } else {
          list = list.filter(val => val.data().partySize >= Number(values[0]));
        }
      }
      if (filter.category) {
        list = list.filter(val => val.data().service === filter.category.value);
      }
      if (listOrder) {
        if (listOrder.value === "priority") {
          const currList = [...list].sort((_a, _b) => {
            const a = _a.data();
            const b = _b.data();
            return (a.priority || 999) - (b.priority || 999);
          });
          list = currList;
        } else if (listOrder.value === "time-left") {
          const currList = [...list].sort((_a, _b) => {
            const a = _a.data();
            const b = _b.data();
            const aTimeLeft =
              DateTime.now().toSeconds() -
              DateTime.fromJSDate(a.checkInTime.toDate())
                .plus({
                  minutes: a.quotedTime
                })
                .toSeconds();
            const bTimeLeft =
              DateTime.now().toSeconds() -
              DateTime.fromJSDate(b.checkInTime.toDate())
                .plus({
                  minutes: b.quotedTime
                })
                .toSeconds();
            return bTimeLeft - aTimeLeft;
          });
          list = currList;
        } else {
          // leave current order
        }
      }
      setFilteredGuestList(list);
    } else {
      setFilteredGuestList(guestList);
    }
  }, [filter, guestList, user, notificationLimit, listOrder]);

  useEffect(() => {
    let interval = setInterval(() => setWaitTimeLastUpdated(Date.now()), 10000);
    return () => {
      clearInterval(interval);
    };
  }, [waitTimeLastUpdated]);

  const getWaitedTime = item => {
    if (item.data().firstNotifiedTime) {
      return item.data().firstNotifiedTime.toDate();
    }

    return undefined;
  };

  const getHistoryWaitedTime = visit => {
    if (visit.firstNotifiedTime) {
      return Math.round((visit.firstNotifiedTime.seconds - visit.checkInTime.seconds) / 60) + " min";
    }
    if (visit.seatedTime) {
      return Math.round((visit.seatedTime.seconds - visit.checkInTime.seconds) / 60) + " min";
    }
    return "";
  };

  const hideInfoAlert = () => {
    setShowInfoAlert(false);
    setInfoAlert(null);
  };

  const hideConfirmAlert = () => {
    setShowConfirmAlert(false);
    setConfirmAlert(null);
  };

  const hideDeleteAlert = () => {
    setShowDeleteAlert(false);
    setVisitToDelete(null);
  };

  const getMessagesCount = id => {
    if (props.messages && props.messages.length) {
      let count = 0;
      for (let message of props.messages) {
        const m = message.data();
        if (
          m.referenceId === id &&
          m.collection === MESSAGE_TYPES.VISIT.collection &&
          m.direction === "inbound" &&
          !m.read
        ) {
          count++;
        }
      }
      return count > 0 ? count : null;
    }
  };

  const getNotificationType = (account, business) => {
    if (business && business.settings && business.settings.notificationType) {
      return business.settings.notificationType;
    } else if (account && account.settings && account.settings.notificationType) {
      return account.settings.notificationType;
    } else {
      return "sms";
    }
  };

  const triggerNotification = async visit => {
    const response = await notifyGuest(visit);
    logEvent(FIREBASE_EVENTS.visit_notify_success);
    if (!response.ok) {
      setInfoAlert(notifyErrorAlert);
      setShowInfoAlert(true);
    }
    setSelectedVisit(null);
  };

  const notifyPatry = () => {
    hideConfirmAlert();
    triggerNotification(selectedVisit);
  };

  const notifyPartyAgain = visit => {
    const notifiedMoment = moment(visit.data().notifiedTime.toDate());
    const diffSeconds = moment().diff(notifiedMoment, "seconds");
    if (business.settings.autoNotify && visit.data().firstNotifiedTime) {
      setInfoAlert(autoNofications);
      setShowInfoAlert(true);
      return;
    }
    if (diffSeconds <= 60) {
      setInfoAlert(spamAlert);
      setShowInfoAlert(true);
      logEvent(FIREBASE_EVENTS.visit_notify_too_soon);
    } else {
      triggerConfirmationAlert(visit);
    }
  };

  const openChatModal = visit => {
    logEvent(FIREBASE_EVENTS.message_view);
    if (visit.data().errorCode) {
      let message =
        visit.data().errorCode === "spam" || visit.data().errorCode === "invalid-number"
          ? invalidNumber
          : visit.data().errorCode === "opt-out"
          ? optOut
          : {};
      setInfoAlert(message);
    }
    setChat(visit);
    markMessagesAsRead(props.messages);
  };

  const handleChatClose = () => {
    setChat(null);
    setInfoAlert(null);
  };

  const triggerConfirmationAlert = visit => {
    if (
      account?.plan &&
      account.plan !== "free" &&
      !(
        account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.VERIFIED ||
        account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.SUBMITTED
      ) &&
      verificationMode === VERIFICATION_MODES.ON &&
      !isGracePeriodAfterNow(account?.phoneConfig)
    ) {
      setVerifyAlert(true);
      return;
    }

    setSelectedVisit(visit);
    if (getNotificationType(account, business) === "email") {
      setConfirmAlert(notifyGuestEmailAlert);
    } else {
      setConfirmAlert(notifyPartyAlert);
    }
    setShowConfirmAlert(true);
  };

  const hideVerifyAlert = () => {
    setVerifyAlert(false);
  };

  const showVerifyBusinessModal = () => {
    hideVerifyAlert();
    window.location.replace("#" + document.URL.split("#")[1] + "?action=verify");
  };

  const promptNotification = visit => {
    if (limitReached) {
      return;
    }
    if (visit.data().errorCode) {
      let message =
        visit.data().errorCode === "spam" || visit.data().errorCode === "invalid-number"
          ? invalidNumber
          : visit.data().errorCode === "opt-out"
          ? optOut
          : {};
      setInfoAlert(message);
      setShowInfoAlert(true);
      return;
    }
    if (!visit.data().phone && getNotificationType(account, business) === "sms") {
      setInfoAlert(noPhoneNumAlert);
      logEvent(FIREBASE_EVENTS.visit_notify_no_phone);
      return setShowInfoAlert(true);
    } else if (!visit.data().email && getNotificationType(account, business) === "email") {
      setInfoAlert(noEmailAlert);
      logEvent(FIREBASE_EVENTS.visit_notify_no_phone);
      return setShowInfoAlert(true);
    } else {
      triggerConfirmationAlert(visit);
    }
  };

  const seatUserClick = item => {
    seatUser(item);
  };

  const editVisitClick = item => {
    editVisit(item);
  };

  const walkoutVisitClick = item => {
    walkoutVisit(item);
  };

  const removeVisitClick = item => {
    setShowDeleteAlert(true);
    setVisitToDelete(item);
  };

  const onDeleteVisit = () => {
    if (visitToDelete) {
      removeVisit(visitToDelete);
    }
    hideDeleteAlert();
  };

  const addNoteClick = item => {
    addNote(item);
  };

  const restoreUserClick = item => {
    restoreVisitorToWaitlist(item);
  };

  const getWaitTimeStyleColor = item => {
    const minutes = getTimeDifferenceInMinutes(item.data().checkInTime.toDate(), getWaitedTime(item));
    if (history) {
      return "";
    }
    if (minutes >= item.data().quotedTime) {
      return "#fb404b";
    } else if (minutes >= item.data().quotedTime - 2) {
      return "orange";
    }
    return "";
  };

  const allowDelete = business => {
    return business.settings.allowDelete === undefined || business.settings.allowDelete;
  };

  const hasNote = item => {
    return item.data().note || item.data().orderNumber;
  };

  const isChatEnabledForAccount = () => {
    if (business && business.settings && business.settings.twoWayMessaging !== undefined) {
      return business.settings.twoWayMessaging;
    }
    if (account && account.settings && account.settings.twoWayMessaging !== undefined) {
      return account.settings.twoWayMessaging;
    }
    return true;
  };

  const mapStatus = status => {
    switch (status) {
      case VisitStatus.walkout:
        return "No Show";
      case VisitStatus.seated:
        return "Served";
      default:
        return "No Show";
    }
  };

  const onGoToUpgrade = () => {
    hideUpgradeAlert();
    let url = document.URL.split("#")[0] + "#/admin/upgrade";
    window.location.replace(url);
  };

  const hideUpgradeAlert = () => {
    setLimitMessage(null);
  };

  const generateOrderUrl = (visit, business) => {
    //seach for '{orderNumber}' in  business.settings.advanced.orderUrl and replace with visit.data().orderNumber
    let url = business?.settings?.advanced?.orderUrl;
    if (url && visit.data().orderNumber) {
      url = url.replace("{orderNumber}", visit.data().orderNumber);
    }
    return url;
  };

  const orderNumberFormatted = item => {
    if (business?.settings?.advanced?.omsIntegration) {
      return (
        <a target="_blank" href={generateOrderUrl(item, business)}>
          Order number: {item.data().orderNumber}
        </a>
      );
    } else {
      return <span>Order Number: {item.data().orderNumber}</span>;
    }
    return "";
  };

  const noteFormatted = item => {
    let result = item.data().note ? item.data().note : "";
    if (hasNote(item)) {
      if (item.data().orderNumber) {
        return (
          <span>
            {orderNumberFormatted(item)} {item.data().note || ""}
          </span>
        );
      }
    }
    return <span>{result}</span>;
  };

  const shouldBeDisabled = item => {
    if (disabled) {
      return true;
    }
    const { phone, visitCount, email } = item.data();
    return (
      (getNotificationType(account, business) !== "email" && !phone) ||
      (visitCount && notificationLimit < visitCount) ||
      (getNotificationType(account, business) === "email" && !email)
    );
  };

  const getPreferenceColor = service => {
    const index = business?.settings?.services?.findIndex?.(s => s.name === service);
    if (index === undefined || index === -1) {
      return "";
    }
    return getPreferenceColorByIndex(index);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  async function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      let newPriorities = [];
      setFilteredGuestList(items => {
        const oldIndex = items.findIndex(el => el.id === active.id);
        const newIndex = items.findIndex(el => el.id === over.id);

        const newItems = arrayMove(items, oldIndex, newIndex);
        newPriorities = [...newItems.map((i, idx) => ({ id: i.id, priority: idx + 1 }))];

        return newItems;
      });
      setNewWaitlistPriorities(newPriorities.map((i, idx) => ({ id: i.id, priority: idx + 1 })));
    }
  }

  return business && filteredGuestList ? (
    <div>
      {verifyAlert && (
        <SweetAlert
          showCancel
          title={"Business Verification Required"}
          cancelBtnText="Cancel"
          confirmBtnText="Verify Now"
          onCancel={hideVerifyAlert}
          onConfirm={showVerifyBusinessModal}
        >
          In order to send sms messages, you'll need to verify your business.
        </SweetAlert>
      )}
      {showDeleteAlert && deleteVisitMessage && (
        <SweetAlert
          showCancel
          title={deleteVisitMessage.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={hideDeleteAlert}
          onConfirm={onDeleteVisit}
        >
          {deleteVisitMessage.message}
        </SweetAlert>
      )}
      {limitMessage && (
        <SweetAlert
          showCancel
          title={limitMessage.title}
          cancelBtnText="No"
          confirmBtnText="Upgrade"
          onCancel={hideUpgradeAlert}
          onConfirm={onGoToUpgrade}
        >
          {limitMessage.message}
        </SweetAlert>
      )}
      {showInfoAlert ? (
        <SweetAlert title={infoAlert.title} onConfirm={hideInfoAlert}>
          {infoAlert.message}
        </SweetAlert>
      ) : (
        ""
      )}
      {showConfirmAlert ? (
        <SweetAlert
          showCancel
          title={confirmAlert.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={hideConfirmAlert}
          onConfirm={notifyPatry}
        >
          {confirmAlert.message}
        </SweetAlert>
      ) : (
        ""
      )}
      {chat ? (
        <ChatModal
          accountId={user.accountId}
          sendMessage={sendMessage}
          error={infoAlert}
          chat={chat}
          plan={account.plan}
          handleChatClose={handleChatClose}
        />
      ) : (
        ""
      )}
      <div className="wl-table__wrapper">
        {history ? null : (
          <div className="wl-table__filter">
            <WaitlistFilter
              business={business}
              filter={filter}
              setFilter={setFilter}
              isReordering={isPriorityOrdering}
            />
          </div>
        )}
        <div
          className={cx({
            "wl-table__header": true,
            "wl-table__header--history": history
          })}
        >
          {isPriorityOrdering ? (
            <>Drag to Change priority</>
          ) : (
            <>{`${isTablet ? "" : business.name} ${history ? "History" : `${isMobile ? "" : "Waitlist"}`}`}</>
          )}
          {!history &&
            (business.settings?.enableWaitlistReorder === undefined || business.settings?.enableWaitlistReorder) && (
              <div className="wl-table__ordering">
                <div>
                  {!isMobile && (
                    <button
                      onClick={() => {
                        setIsPriorityOrdering(!isPriorityOrdering);
                      }}
                    >
                      {isPriorityOrdering ? "Done" : "Edit"}
                    </button>
                  )}
                  <WaitlistOrder isEdit={isPriorityOrdering} listOrder={listOrder} setListOrder={setListOrder} />
                </div>
              </div>
            )}
        </div>
        {filteredGuestList.length ? (
          isMobile ? (
            <div className="wl-mobile-table">
              {filteredGuestList.map((item, index) => {
                const preferenceColor = getPreferenceColor(item.data().service);
                return (
                  <div className="wl-mobile-table__item-wrapper" key={"wl-mobile-item-" + index}>
                    <div className="wl-mobile-table__item">
                      <div className="wl-mobile-table__item__order">
                        <span>{index + 1}.</span>
                      </div>
                      <div className="wl-mobile-table__item__info">
                        <div className="wl-mobile-table__item__info__name">
                          <span>{item.data().name}</span>
                        </div>
                        <div className="wl-mobile-table__item__info__party-size">
                          <i className="fa fa-user" aria-hidden="true"></i>
                          <span>{item.data().partySize}</span>
                          <span>{item.data().partySize > 1 ? " people" : " person"}</span>
                        </div>
                        <div className="wl-mobile-table__item__info__time">
                          <i
                            className="fa fa-clock-o"
                            style={{ color: getWaitTimeStyleColor(item) }}
                            aria-hidden="true"
                          />
                          {history ? (
                            <span>{`${getHistoryWaitedTime(item.data())}`}</span>
                          ) : (
                            <span style={{ color: getWaitTimeStyleColor(item) }}>
                              <TimerTick
                                date={item.data().checkInTime.toDate()}
                                date2={getWaitedTime(item)}
                                increments="minutes"
                              />
                              {` / ${item.data().quotedTime} minutes`}
                            </span>
                          )}
                        </div>
                        {business.settings && business.settings.services && business.settings.services.length ? (
                          <div
                            className={cx({
                              "wl-mobile-table__item__info__category-value": true,
                              colored: !!item.data().service && preferenceColor
                            })}
                            style={{
                              backgroundColor: preferenceColor
                            }}
                          >
                            {item.data().service || "None"}
                          </div>
                        ) : null}
                        {history ? (
                          <div className="wl-mobile-table__item__info__status">{mapStatus(item.data().status)}</div>
                        ) : null}
                      </div>
                      <div className="wl-mobile-table__item__buttons">
                        {history ? (
                          <ButtonHistoryRestore disabled={disabled} onClick={restoreUserClick.bind(this, item)} />
                        ) : (
                          <ButtonsWaitlist
                            isChatEnabledForAccount={isChatEnabledForAccount}
                            item={item}
                            shouldBeDisabled={shouldBeDisabled}
                            getMessagesCount={getMessagesCount}
                            openChatModal={openChatModal}
                            disabled={disabled}
                            notifyPartyAgain={notifyPartyAgain}
                            promptNotification={promptNotification}
                            seatUserClick={seatUserClick}
                            allowDelete={allowDelete}
                            business={business}
                            editVisitClick={editVisitClick}
                            walkoutVisitClick={walkoutVisitClick}
                            removeVisitClick={removeVisitClick}
                            addNoteClick={addNoteClick}
                            isMobile={true}
                          />
                        )}
                      </div>
                    </div>
                    {hasNote(item) && <div className="wl-mobile-table__item__note">NOTE: {noteFormatted(item)}</div>}
                  </div>
                );
              })}
            </div>
          ) : (
            <Table responsive className="table-bigboy wl-table">
              <thead>
                <tr>
                  <th className="wl-table__order">#</th>
                  <th>Name</th>
                  <th style={{ width: "90px" }}> </th>
                  {business.settings && business.settings.services && business.settings.services.length ? (
                    <th className="wl-table__category">Preference</th>
                  ) : null}
                  {history ? <th className="wl-table__category">Status</th> : null}
                  <th className="wl-table__stats">Stats</th>
                  <th className="wl-table__actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis]}
                >
                  <SortableContext items={filteredGuestList} strategy={verticalListSortingStrategy}>
                    {filteredGuestList.map((item, index) => {
                      const preferenceColor = getPreferenceColor(item.data().service);
                      return (
                        <WaitlistTableSortableItemWrapper key={index} id={item.id} isEdit={isPriorityOrdering}>
                          {!isPriorityOrdering && (
                            <td>
                              <div className="wl-table__order wl-table__text-xl">{index + 1}</div>
                            </td>
                          )}
                          <td className={`wl-table__text-xl ${hasNote(item) ? "wl-table__with-note" : ""}`}>
                            <div>{item.data().name}</div>
                            {hasNote(item) ? <p style={{ marginBottom: "4px" }}>NOTE: {noteFormatted(item)}</p> : ""}
                          </td>
                          <td>
                            {item.data().origin === "api" && item.data().selfCheckIn && (
                              <div className="wl-table__category-value wl-table__text-md">Web check-in</div>
                            )}
                          </td>
                          {business.settings && business.settings.services && business.settings.services.length ? (
                            <td>
                              <div
                                className={cx({
                                  "wl-table__category-value wl-table__text-md": true,
                                  colored: !!item.data().service && preferenceColor
                                })}
                                style={{
                                  backgroundColor: preferenceColor
                                }}
                              >
                                {item.data().service || "None"}
                              </div>
                            </td>
                          ) : null}
                          {history ? (
                            <td>
                              <div className="wl-table__text-lg">{mapStatus(item.data().status)}</div>
                            </td>
                          ) : null}
                          <td className="wl-table__stats wl-table__text-lg">
                            <div className="flex-container">
                              <div className="flex-item">
                                <i className="fa fa-user" aria-hidden="true" />
                                <span>
                                  {item.data().partySize} {item.data().partySize === 1 ? "person" : "people"}
                                </span>
                              </div>
                              <div className="flex-item">
                                <i
                                  className="fa fa-clock-o"
                                  style={{ color: getWaitTimeStyleColor(item) }}
                                  aria-hidden="true"
                                ></i>
                                {history ? (
                                  <span>{`${getHistoryWaitedTime(item.data())}`}</span>
                                ) : (
                                  <span style={{ color: getWaitTimeStyleColor(item) }}>
                                    <TimerTick
                                      date={item.data().checkInTime.toDate()}
                                      date2={getWaitedTime(item)}
                                      increments="minutes"
                                    ></TimerTick>
                                    {` / ${item.data().quotedTime} min`}
                                  </span>
                                )}
                              </div>
                              <div className="flex-item">
                                {business?.settings?.advanced?.lobbyFeature && item.data().lobbyStatus === true ? (
                                  <>
                                    <i
                                      className="mdi mdi-sofa-single-outline"
                                      style={{ marginRight: "6px" }}
                                      aria-hidden="true"
                                    />
                                    <span>Lobby</span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                              {}
                              <div className="flex-item">
                                {business.settings?.advanced?.omsIntegration && item.data().fulfillmentStatus ? (
                                  <>
                                    <i
                                      className="mdi mdi-shopping-outline"
                                      style={{ marginRight: "6px" }}
                                      aria-hidden="true"
                                    />
                                    <span>{item.data().fulfillmentStatus}</span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </td>

                          {history ? (
                            <td>
                              <ButtonHistoryRestore disabled={disabled} onClick={restoreUserClick.bind(this, item)} />
                            </td>
                          ) : (
                            <td>
                              <ButtonsWaitlist
                                isChatEnabledForAccount={isChatEnabledForAccount}
                                item={item}
                                shouldBeDisabled={shouldBeDisabled}
                                getMessagesCount={getMessagesCount}
                                openChatModal={openChatModal}
                                disabled={disabled}
                                notifyPartyAgain={notifyPartyAgain}
                                promptNotification={promptNotification}
                                seatUserClick={seatUserClick}
                                allowDelete={allowDelete}
                                business={business}
                                editVisitClick={editVisitClick}
                                walkoutVisitClick={walkoutVisitClick}
                                removeVisitClick={removeVisitClick}
                                addNoteClick={addNoteClick}
                              />
                            </td>
                          )}
                        </WaitlistTableSortableItemWrapper>
                      );
                    })}
                  </SortableContext>
                </DndContext>
              </tbody>
            </Table>
          )
        ) : history ? (
          <div className="waitlist-empty">There are no guests in your history</div>
        ) : (
          <div className="waitlist-empty">There are no guests on the waitlist</div>
        )}
      </div>
    </div>
  ) : (
    ""
  );
}

const mapStateToProps = state => {
  const { account } = state;
  return account;
};

export default connect(mapStateToProps, null)(WaitlistTable);
