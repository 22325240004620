import { db, firebase } from "../../firebase";
import * as _ from "lodash";
import { Observable } from "rxjs";
import moment from "moment";
import { environment } from "../../configs/environment";
import { FIREBASE_EVENTS } from "constants/firebase";
import { logEvent } from "services/firebase/firebase.service";

export const VisitStatus = {
  reserved: 0,
  waiting: 1,
  notified: 2,
  seated: 3,
  cancelled: 7,
  confirmedCancel: 8,
  walkout: 9,
  deleted: 10,
  pending: 11
};

export function getAverageWait(value) {
  const totalMinutes = _.sumBy(value, v => v.minutesWaited);
  const totalServed = _.sumBy(value, v => v.partiesServed);
  if (totalMinutes && totalServed) {
    return Math.round(totalMinutes / totalServed);
  } else {
    return 0;
  }
}

function getMinutesDiff(date1, date2) {
  var diffMs = date1 - date2;
  return Math.round(diffMs / 60000);
}

export function getAverageWaitForTimePeriod(allVisitors, time) {
  let count = 0;
  let dateAverage = 0;
  allVisitors.forEach(visit => {
    const seatedTime = visit.data().seatedTime;
    const checkInTime = visit.data().checkInTime;
    const notifiedTime = visit.data().firstNotifiedTime;
    if (seatedTime && moment(new Date()).diff(seatedTime.toDate(), "minutes") < (time || 30)) {
      if (notifiedTime && checkInTime) {
        dateAverage += getMinutesDiff(notifiedTime.toDate(), checkInTime.toDate());
        count++;
      } else if (checkInTime && seatedTime) {
        dateAverage += getMinutesDiff(seatedTime.toDate(), checkInTime.toDate());
        count++;
      }
    }
  });
  return `${Math.round(dateAverage / (count || 1))} mins`;
}

export function getTrend(value) {
  const result = _.findLast(value, v => !!v.trend);
  if (result) {
    return result.trend;
  }
}

export function getGuestsList(businessId, accountId) {
  return new Observable(subscriber => {
    db.collection("visits")
      .where("businessId", "==", businessId)
      .where("accountId", "==", accountId)
      .where(
        "checkInTime",
        ">",
        moment()
          .startOf("day")
          .toDate()
      )
      .orderBy("checkInTime", "asc")
      .onSnapshot(result => {
        subscriber.next(result.docs);
      });
  });
}

export function getGuestsListHistory(businessId, accountId) {
  return new Observable(subscriber => {
    db.collection("visits")
      .where("businessId", "==", businessId)
      .where("status", "in", [VisitStatus.seated, VisitStatus.walkout])
      .where(
        "checkInTime",
        ">",
        moment()
          .startOf("day")
          .toDate()
      )
      .where("accountId", "==", accountId)
      .orderBy("checkInTime", "desc")
      .limit(100)
      .onSnapshot(result => {
        subscriber.next(result.docs);
      });
  });
}

export async function seatUser(visit) {
  const payload = visit.data();
  payload.status = VisitStatus.seated;
  payload.seatedTime = new Date();
  payload.id = visit.id;
  logEvent(FIREBASE_EVENTS.visit_done);
  return addOrUpdateVisit(payload);
}

export async function removeVisit(visit) {
  db.collection("visits")
    .doc(visit.id)
    .set({ ...visit.data(), status: VisitStatus.deleted });
  logEvent(FIREBASE_EVENTS.visit_delete);
}

export async function walkoutVisit(visit) {
  db.collection("visits")
    .doc(visit.id)
    .set({ ...visit.data(), status: VisitStatus.walkout });
  logEvent(FIREBASE_EVENTS.visit_walkout);
}

export async function addNote() {
  alert("yes");
}

export function restoreVisitorToWaitlist(visit) {
  const payload = { ...visit.data() };
  delete payload.seatedTime;
  delete payload.notifiedTime;
  delete payload.firstNotifiedTime;
  payload.status = VisitStatus.waiting;
  db.collection("visits")
    .doc(visit.id)
    .set(payload);
}

export async function notifyGuest(entity, type) {
  return firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(async function(idToken) {
      let url = `${environment.apiEndpoint}notifyGuest/${entity.id}`;
      if (type) {
        url += `?type=${type}`;
      }
      return fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          Accepts: "application/json"
        }
      })
        .then(response => {
          if (!response.ok || (response.status > 200 && response.status < 600)) {
            throw new Error(response.statusText);
          }
          return response;
        })
        .catch(error => {
          console.error("error triggering notifyGuest function", error);
          return { ok: false };
        });
    })
    .catch(function(error) {
      console.error("error getting auth token", error);
      return { ok: false };
    });
}

export async function sendMessage(visitId, message, type) {
  return firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(async function(idToken) {
      let query = "";
      if (type) {
        query += `?type=${type}`;
      }
      return fetch(`${environment.apiEndpoint}notifyGuest/${visitId}${query}`, {
        method: "POST",
        body: JSON.stringify({ message: message }),
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
          Accepts: "application/json"
        }
      })
        .then(response => {
          if (!response.ok || (response.status > 200 && response.status < 600)) {
            throw new Error(response.statusText);
          }
          return response;
        })
        .catch(error => {
          console.error("error triggering notifyGuest POST function", error);
          return { ok: false };
        });
    })
    .catch(function(error) {
      console.error("error getting auth token", error);
      return { ok: false };
    });
}

export function getBusinessRef(id) {
  return db.collection("businesses").doc(id);
}

export async function getBusinessById(id) {
  const result = await getBusinessRef(id).get();
  if (result) {
    return { businessId: result.id, ...result.data() };
  }
}

export async function addOrUpdateVisit(visit) {
  console.log("da visit: ", visit);
  const business = await getBusinessById(visit.businessId);
  visit = { ...visit, businessName: business.name, priority: visit.priority || 999 };
  if (visit.id) {
    return db
      .collection("visits")
      .doc(visit.id)
      .set(_.omit(visit, "id"), { merge: true })
      .then(() => visit);
  } else {
    visit.origin = "web";
    return db
      .collection("visits")
      .add(visit)
      .then(v => {
        return db
          .collection("visits")
          .doc(v.id)
          .get();
      });
  }
}

export async function setNewWaitlistPriorities(arr) {
  const promises = arr.map(v =>
    db
      .collection("visits")
      .doc(v.id)
      .set(
        {
          priority: v.priority || 999
        },
        { merge: true }
      )
  );

  await Promise.all(promises);
}
