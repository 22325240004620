import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SettingsInput from "./SettingsInput";
import { updateBusiness, updateBusinessSettings } from "../../services/profile/profile.service";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import SettingsWrapper from "../../common/ScrollableWrapper/SettingsWrapper";
import { userIsAdmin } from "configs/access";
import { useSelector } from "react-redux";
import { firebase } from "firebase.js";

const DEFAULT_TIMEZONE = "America/New_York";
const MAX_URL_LENGTH = 150;

const AdvancedSettings = ({ business, user }) => {
  const { register, handleSubmit, errors } = useForm();
  const { account } = useSelector(state => state.account || {});
  const [form, setForm] = useState({
    omsIntegration: false,
    orderUrl: "",
    profileUrl: "",
    enableFilteredStatBoxes: false,
    lobbyFeature: false,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    dashboardEnabled: false,
    enableWaitlistReorder: false,
    externalId: ""
  });
  const [loading, setLoading] = useState(false);
  const [infoAlert, setInfoAlert] = useState(null);

  useEffect(() => {
    if (business) {
      setForm({
        orderUrl: sanitizeUrl(business?.settings?.advanced?.orderUrl) || "",
        profileUrl: sanitizeUrl(business?.settings?.advanced?.profileUrl) || "",
        lobbyFeature: business?.settings?.advanced?.lobbyFeature || false,
        enableFilteredStatBoxes: business?.settings?.advanced?.enableFilteredStatBoxes || false,
        omsIntegration: business?.settings?.advanced?.omsIntegration || false,
        timezone: business?.settings?.timezone || DEFAULT_TIMEZONE,
        dashboardEnabled: business.dashboardEnabled || false,
        enableWaitlistReorder: business?.settings?.enableWaitlistReorder || false,
        ...(onCheckIfUserIsAdmin() && { externalId: business.externalId || "" })
      });
    }
    // eslint-disable-next-line
  }, [business]);

  const sanitizeUrl = url => {
    if (url && url !== "") {
      return url.trim();
    }
  };

  const onSubmit = async data => {
    await updateBusiness(business.businessId, {
      ...{
        settings: {
          advanced: {
            omsIntegration: form.omsIntegration,
            orderUrl: form.orderUrl === "" ? firebase.firestore.FieldValue.delete() : form.orderUrl,
            profileUrl: form.profileUrl === "" ? firebase.firestore.FieldValue.delete() : form.profileUrl,
            lobbyFeature: form.lobbyFeature,
            enableFilteredStatBoxes: form.enableFilteredStatBoxes
          },
          enableWaitlistReorder: form.enableWaitlistReorder
        },
        dashboardEnabled: form.dashboardEnabled || false,
        ...(onCheckIfUserIsAdmin() && { externalId: form.externalId })
      },
      ...data
    });
    await updateBusinessSettings(business.businessId, { ...{ timezone: form.timezone }, ...data });
    toastr.success(`Settings updated`);
  };

  const hideInfoAlert = () => {
    setInfoAlert(null);
  };

  const onChangeFormField = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const toggleChecked = event => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const validateUrl = url => {
    if (form.omsIntegration) {
      const domainRegex = /^(((https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;
      return domainRegex.test(url);
    }
    return true;
  };

  const onCheckIfUserIsAdmin = () => {
    return userIsAdmin(user);
  };

  const shouldDisplayExternalId = () => {
    return (
      onCheckIfUserIsAdmin() &&
      business &&
      !business.squareLinked &&
      (account.id === "xj5spEZSQb2wd0nFRKY6" || account.id === "IgBNLUJQqJwa1URhwUAX")
    );
  };

  return (
    <>
      {infoAlert && (
        <SweetAlert title={infoAlert.title} onConfirm={hideInfoAlert}>
          {infoAlert.message}
        </SweetAlert>
      )}
      <SettingsWrapper
        title={`Advanced ${business ? " - " + business.name : ""}`}
        handleSubmit={handleSubmit(onSubmit)}
        buttonStyles={{ maxWidth: 600 }}
      >
        <Row>
          <Col md={12}>
            <SettingsInput
              form={form}
              name={"omsIntegration"}
              type={"switch"}
              enabled={true}
              errors={errors}
              value={form.omsIntegration}
              label={"OMS+ Integration"}
              register={null}
              onChangeValue={toggleChecked}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SettingsInput
              placeholder={"https://www.example.com"}
              form={form}
              value={form.orderUrl}
              enabled={form.omsIntegration}
              allowEdit={true}
              name={"orderUrl"}
              type={"text"}
              inputStyles={{ width: "400px" }}
              errors={errors}
              label={"OMS+ Order URL"}
              subtitle={"The Url to open an order in OMS+"}
              onChangeValue={onChangeFormField}
              register={register({
                validate: validateUrl,
                maxLength: { value: MAX_URL_LENGTH, message: `URL must be fewer than ${MAX_URL_LENGTH} characters` }
              })}
              validateMessage={"Invalid URL"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SettingsInput
              placeholder={"https://www.example.com"}
              form={form}
              value={form.profileUrl}
              enabled={form.omsIntegration}
              inputStyles={{ width: "400px" }}
              allowEdit={true}
              name={"profileUrl"}
              type={"text"}
              errors={errors}
              label={"OMS+ Customer Profile URL"}
              subtitle={"The Url to open a customer profile in OMS+"}
              onChangeValue={onChangeFormField}
              register={register({
                validate: validateUrl,
                maxLength: { value: MAX_URL_LENGTH, message: `URL must be fewer than ${MAX_URL_LENGTH} characters` }
              })}
              validateMessage={"Invalid URL"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SettingsInput
              form={form}
              name={"lobbyFeature"}
              type={"switch"}
              enabled={true}
              errors={errors}
              value={form.lobbyFeature}
              label={"Enable Lobby Toggle"}
              register={null}
              onChangeValue={toggleChecked}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SettingsInput
              form={form}
              name={"enableWaitlistReorder"}
              type={"switch"}
              enabled={true}
              errors={errors}
              value={form.enableWaitlistReorder}
              label={"Enable Waitlist Reordering"}
              register={null}
              onChangeValue={toggleChecked}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <SettingsInput
              form={form}
              name={"enableFilteredStatBoxes"}
              type={"switch"}
              enabled={true}
              errors={errors}
              value={form.enableFilteredStatBoxes}
              label={"Enable Filtered Stat Boxes"}
              register={null}
              onChangeValue={toggleChecked}
            />
          </Col>
        </Row>
        {shouldDisplayExternalId() && (
          <>
            <Row>
              <Col md={12}>
                <SettingsInput
                  placeholder={"External ID"}
                  form={form}
                  value={form.externalId}
                  enabled={true}
                  name={"externalId"}
                  subtitle={"Store SAP ID, changing may impact API calls. PROCEED WITH CAUTION."}
                  type={"text"}
                  errors={errors}
                  label={"External ID"}
                  onChangeValue={onChangeFormField}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <SettingsInput
                  placeholder={"Waitly ID"}
                  value={business.businessId}
                  enabled={false}
                  name={"waitlyId"}
                  type={"text"}
                  errors={errors}
                  label={"Waitly ID"}
                />
              </Col>
            </Row>
          </>
        )}
        {business && business.squareLinked && (
          <Row>
            <Col md={9}>
              <SettingsInput
                form={form}
                value={form.externalId}
                enabled={true}
                name={"squareBadge"}
                type={"square-badge"}
                label={"Linked to"}
              />
            </Col>
          </Row>
        )}
      </SettingsWrapper>
    </>
  );
};

export default AdvancedSettings;
