import React, { useEffect, useState } from "react";
import TextMessages from "./TextMessages";
import { SETTINGS_MENU, SETTINGS_MENU_KEYS } from "../../constants/settings";
import GeneralSettings from "./GeneralSettings";
import WaitlistSettings from "./WaitlistSettings";
import CategorySettings from "./CategorySettings";
import ReservationSettings from "./ReservationSettings";
import WidgetSettings from "./WidgetSettings";
import AdvancedSettings from "./AdvancedSettings";
import AreasSettings from "./AreasSettings";
import UsersSettings from "./UsersSettings";
import { useDispatch, useSelector } from "react-redux";
import "./Settings.scss";
import { setBusiness } from "../../store/actions/business.actions";
import RegionSettings from "./RegionSettings";
import SquareSettings from "./SquareSettings";
import { Subject } from "rxjs";
import { useHistory } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import SettingSectionTab from "./SettingSectionTab";
import InvitesSettings from "./InvitesSettings";
import AccountSettings from "./AccountSettings";
import AddressSettings from "./AddressSettings";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useMediaQuery } from "hooks/useMediaQuery";
import "react-spring-bottom-sheet/dist/style.css";
import { SettingsTabContext } from "context/SettingsTabsContext";
import AppearanceSettings from "./AppearanceSettings";
import { isBigAccount } from "configs/access";
import { InputFieldsSettings } from "components/Settings/InputFieldsSettings";

function Settings(props) {
  const user = props.user;
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const businessSelector = useSelector(state => state.business);
  const dispatch = useDispatch();
  const { business, businesses } = businessSelector;
  const [openBottomSheet, setOpenBottomSheet] = useState(false);

  const isMobile = useMediaQuery("(max-width: 767px)");

  const findSettingMenuWithKey = key => {
    return SETTINGS_MENU.map(menu => menu.children)
      .flat()
      .find(stng => stng.key === key);
  };

  const [active, setActive] = useState(findSettingMenuWithKey(SETTINGS_MENU_KEYS.LOCATION_GENERAL));
  const [page, setPage] = useState(null);

  useEffect(() => {
    let params = new URLSearchParams(props.location.search);
    const pageParam = params.get("page");
    if (!isBigAccount({ id: user.accountId }) && pageParam === SETTINGS_MENU_KEYS.ADVANCED) {
      setPage(SETTINGS_MENU_KEYS.LOCATION_GENERAL);
    } else {
      setPage(pageParam);
      if (params.get("source") === "square") {
        setActive("Connect to Square");
      }
    }
    const destroy$ = new Subject();
    return () => {
      destroy$.next(true);
      destroy$.complete();
    };
  }, [props.location.search]);

  useEffect(() => {
    if (!page || page === SETTINGS_MENU_KEYS.LOCATION_GENERAL) {
      return setActive(findSettingMenuWithKey(SETTINGS_MENU_KEYS.LOCATION_GENERAL));
    }
    setActive(findSettingMenuWithKey(page));
  }, [page]);

  const isActive = setting => {
    return active?.key === setting?.key;
  };

  const onSetActiveTab = setting => () => {
    setOpenBottomSheet(false);
    let pageSearch = setting?.key ?? SETTINGS_MENU_KEYS.LOCATION_GENERAL;
    if (setting?.key !== page) {
      history.push({
        pathname: "/admin/settings",
        search: "?page=" + pageSearch
      });
    }
  };

  const onShowDeleteLocationButton = () => {
    return business && businesses?.length > 0 && user.businessId !== business.businessId;
  };

  const onSetNewSelectedBusiness = () => {
    dispatch(setBusiness(businesses.find(business => business.businessId === user.businessId)));
  };

  const onRenderTabs = () => {
    if (!businesses) {
      return (
        <div className="loader-wrapper">
          <BeatLoader margin={2.5} size={10} color={"#016BFF"} />
        </div>
      );
    }

    switch (active.key) {
      case SETTINGS_MENU_KEYS.LOCATION_GENERAL:
        return (
          <GeneralSettings
            user={user}
            plan={props.plan}
            business={business}
            setSelectedBusiness={onSetNewSelectedBusiness}
            showDeleteButton={onShowDeleteLocationButton}
          />
        );
      case SETTINGS_MENU_KEYS.ADDRESS:
        return <AddressSettings user={user} plan={props.plan} business={business} />;
      case SETTINGS_MENU_KEYS.TEXT_MESSAGES:
        return (
          <TextMessages
            user={user}
            plan={props.plan}
            businessId={business.businessId}
            settings={business.settings}
            businessName={business.name}
          />
        );
      case SETTINGS_MENU_KEYS.SELF_CHECK_IN:
        return (
          <WaitlistSettings
            plan={props.plan}
            businessId={business.businessId}
            settings={business.settings}
            businessName={business.name}
          />
        );
      case SETTINGS_MENU_KEYS.CATEGORIES:
        return (
          <CategorySettings
            plan={props.plan}
            businessId={business.businessId}
            settings={business.settings}
            businessName={business.name}
          />
        );
      case SETTINGS_MENU_KEYS.RESERVATIONS:
        return (
          <ReservationSettings
            plan={props.plan}
            location={props.location}
            business={business}
            settings={business.settings}
            businessName={business.name}
            user={user}
          />
        );
      case SETTINGS_MENU_KEYS.INPUT_FIELDS:
        return (
          <InputFieldsSettings
            plan={props.plan}
            businessId={business.businessId}
            settings={business.settings}
            businessName={business.name}
          />
        );
      case SETTINGS_MENU_KEYS.ADVANCED:
        return (
          <AdvancedSettings
            user={user}
            plan={props.plan}
            business={business}
            setSelectedBusiness={onSetNewSelectedBusiness}
            showDeleteButton={onShowDeleteLocationButton}
          />
        );
      case SETTINGS_MENU_KEYS.AREAS:
        return <AreasSettings plan={props.plan} businessId={business.businessId} settings={business.settings} />;
      case SETTINGS_MENU_KEYS.REGIONS:
        return <RegionSettings plan={props.plan} businessId={business.businessId} settings={business.settings} />;
      case SETTINGS_MENU_KEYS.ACCOUNT_GENERAL:
        return <AccountSettings user={user} business={business} />;
      case SETTINGS_MENU_KEYS.WIDGETS:
        return <WidgetSettings user={user} business={business} plan={props.plan} />;
      case SETTINGS_MENU_KEYS.APPEARANCE:
        return <AppearanceSettings user={user} business={business} plan={props.plan} />;
      case SETTINGS_MENU_KEYS.USERS:
        return <UsersSettings business={business} user={user} />;
      case SETTINGS_MENU_KEYS.INVITES:
        return <InvitesSettings business={business} />;
      case SETTINGS_MENU_KEYS.CONNECT_TO_SQUARE:
        return business ? (
          <SquareSettings
            location={props.location}
            business={business.business}
            settings={business.settings}
            user={user}
            plan={props.plan}
          />
        ) : null;
      default:
        return null;
    }
  };

  const onCheckIfToShowOption = setting => {
    if (setting === "Advanced") {
      return isBigAccount({ id: user.accountId });
    }
    if (setting === "Regions" || setting === "Areas") {
      return (props.plan === "enterprise" || props.plan === "pro") && user.claims.role === "admin";
    } else if (setting === "Users" || setting === "User Invites") {
      return (
        (props.plan === "enterprise" || props.plan === "pro" || props.plan === "premium") &&
        user.claims.role === "admin"
      );
    } else if (setting === "Connect to Square" || setting === "Appearance") {
      return user.claims.role === "admin";
    } else if (setting === "Widgets" && isBigAccount({ id: user.accountId })) {
      return false;
    } else {
      return true;
    }
  };

  const settingMenu = SETTINGS_MENU.map(section => ({
    ...section,
    children: section.children.filter(child => onCheckIfToShowOption(child.name))
  })).filter(section => section.children.length);

  const settingTabs = settingMenu.map((setting, index) => (
    <SettingSectionTab section={setting} key={index} setActive={onSetActiveTab} isActive={isActive} />
  ));

  return (
    <SettingsTabContext.Provider
      value={{
        isBtnVisible: isMobile,
        onBtnClick: () => setOpenBottomSheet(true)
      }}
    >
      <div className="settings-wrapper">
        <div className="settings flex">
          {isMobile ? (
            <BottomSheet open={openBottomSheet} onDismiss={() => setOpenBottomSheet(false)}>
              <div style={{ marginBottom: 30 }}>{settingTabs}</div>
            </BottomSheet>
          ) : (
            <div className="settings-tabs-wrapper">{settingTabs}</div>
          )}
          <div className="settings-tabs-options">{onRenderTabs()}</div>
        </div>
      </div>
    </SettingsTabContext.Provider>
  );
}

export default Settings;
