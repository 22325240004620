import React, { useEffect } from "react";
import "./WaitlistFilter.scss";
import Select from "react-select";

const customStyles = {
  container: (_, { selectProps: { width } }) => ({
    width,
    marginRight: "14px"
  }),
  singleValue: styles => ({
    ...styles,
    fontSize: "14px"
  }),
  option: styles => ({
    ...styles,
    fontSize: "14px"
  })
};

function WaitlistFilter(props) {
  const { filter, setFilter, business } = props;
  const isPreferenceAvailable = Boolean(
    business.settings && business.settings.services && business.settings.services.length
  );
  const preferenceField = business?.settings?.fields?.service;
  const partySizeField = business?.settings?.fields?.partySize;
  const isPartySizeFilterAvailable =
    partySizeField?.enabled && partySizeField?.waitlist && partySizeField?.waitlist !== "hidden";
  const isPreferenceFilterAvailable =
    isPreferenceAvailable &&
    preferenceField?.enabled &&
    preferenceField?.waitlist &&
    preferenceField?.waitlist !== "hidden";
  let categories = [];
  if (business.settings && business.settings.services && business.settings.services.length) {
    categories = business.settings.services.map(service => {
      return {
        value: service.name,
        label: service.name
      };
    });
  }

  const parties = [
    { value: "1-2", label: "1-2" },
    { value: "3-4", label: "3-4" },
    { value: "5", label: "5+" }
  ];

  const groupedOptions = [
    isPartySizeFilterAvailable && {
      label: "Party size",
      options: parties
    },
    isPreferenceFilterAvailable && {
      label: "Preference",
      options: categories
    }
  ].filter(Boolean);

  const resetFilter = () => {
    setFilter({ partySize: null, category: null });
  };

  const findType = selectedOption => {
    const { label, value } = selectedOption;
    if (parties.find(p => p.value === value && p.label === label)) {
      return "partySize";
    } else {
      return "category";
    }
  };

  const onChangeGroupedValue = selectedOption => {
    const type = findType(selectedOption);
    setFilter({ [type]: selectedOption });
  };

  useEffect(() => {
    if (!isPartySizeFilterAvailable && !isPreferenceFilterAvailable) {
      resetFilter();
    }
  }, [isPartySizeFilterAvailable, isPreferenceFilterAvailable]);

  useEffect(() => {
    if (props.isReordering) {
      resetFilter();
    }
  }, [props.isReordering]);

  return (
    <div className="wl-filter">
      <div className="wl-filter__row">
        {!!groupedOptions.length && (
          <Select
            onChange={onChangeGroupedValue}
            styles={customStyles}
            isDisabled={!!props.isReordering}
            placeholder="Filter"
            value={filter.partySize || filter.category}
            options={groupedOptions}
            width="180px"
            isSearchable={false}
          />
        )}
      </div>
      {filter.partySize || filter.category ? (
        <div className="wl-filter__filter-details">
          <div className="wl-filter__column">Waitlist filtered.</div>
          <div className="wl-filter__clear" onClick={resetFilter}>
            Show All
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default WaitlistFilter;
