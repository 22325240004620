import React, { useState, useEffect } from "react";
import { firebase } from "../firebase";
import { Grid, Col, Row } from "react-bootstrap";
import WaitlistTable from "components/WaitlistTable/WaitlistTable";
import CustomIconButton from "components/CustomButton/CustomIconButton.jsx";
import "../assets/sass/pages/waitlist.scss";
import { useHistory } from "react-router-dom";
import {
  removeVisit,
  walkoutVisit,
  VisitStatus,
  seatUser,
  addOrUpdateVisit,
  notifyGuest,
  sendMessage
} from "../services/visits/visits.service.js";
import { Subject } from "rxjs";
import AddGuestModal, { AddGuestForm } from "components/AddGuest/AddGuest";
import AddNoteModal from "components/AddNote/AddNote";
import WaitlistStats from "components/WaitlistStats/WaitlistStats";
import { useSelector } from "react-redux";
import { logEvent } from "services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "constants/firebase";
import SweetAlert from "react-bootstrap-sweetalert";
import SquareSetupModal from "components/Square/SquareSetupModal";
import { PHONE_CONFIG_STATUSES } from "constants/verification";
import { Business } from "types/business";
import "./Waitlist.scss";
import useLocalStorage from "../hooks/useLocalStorage";

function Waitlist(props) {
  const history = useHistory();
  const aggregateSelector = useSelector<any>(state => state.aggregate);
  const visitSelector = useSelector<any>(state => state.visit);
  const business: Business = useSelector<any>(state => state.business.business) as Business;
  const squareInfo = useSelector<any>(state => state.square.squareInfo);
  const { dashboardStats } = aggregateSelector as any;
  const { waitingVisitors, allVisitors, messages } = visitSelector as any;
  const [showGuestForm, setShowGuestForm] = useState(false);
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [guestForm, setGuestForm] = useState<any>(null);
  const [noteForm, setNoteForm] = useState<any>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [squareSetupModalConfig, setSquareSetupModalConfig] = useState<any>();
  const { verificationMode } = useSelector<any>(state => state.config || {}) as any;
  const { phoneConfig } = useSelector<any>(state => state.account?.account || {}) as any;
  const [isSourceTour, setIsSourceTour] = useState(false);
  const [waitlistFilter, setWaitlistFilter] = useLocalStorage("waitlistFilter", { partySize: null, category: null });
  const [listOrder, setListOrder] = useLocalStorage("waitlistOrder", null);

  const isVerifyTurnedOn = (verificationMode, phoneConfig) =>
    !(
      phoneConfig?.status === PHONE_CONFIG_STATUSES.SUBMITTED || phoneConfig?.status === PHONE_CONFIG_STATUSES.VERIFIED
    );

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if (params.get("success")) {
      setShowSuccessAlert(true);
      if (params.get("source") === "tour") {
        logEvent(FIREBASE_EVENTS.intro_tour_activate_success);
      }
    }
    if (params.get("source") === "square") {
      setSquareSetupModalConfig({
        newAccount: params.get("newAccount") === "true",
        reconnect: params.get("reconnect") === "true",
        error: params.get("squareError"),
        step: params.get("step"),
        plan: params.get("plan")
      });
    }
    if (params.get("source") === "tour") {
      setIsSourceTour(true);
    } else {
      setIsSourceTour(false);
    }
    const destroy$ = new Subject();
    return () => {
      destroy$.next(true);
      destroy$.complete();
    };
  }, [props.location.search]);

  const editVisit = visit => {
    setGuestForm(visit);
    handleGuestFormShow();
    logEvent(FIREBASE_EVENTS.visit_edit);
  };

  const addNoteForm = visit => {
    setNoteForm(visit);
    setShowNoteForm(true);
  };

  const handleAddNoteFormClose = async (form: any) => {
    setShowNoteForm(false);
    if (form) {
      addOrUpdateVisit({
        id: noteForm?.id,
        note: form.note,
        businessId: noteForm?.data().businessId
      });
    }
  };

  const handleGuestFormClose = async (form?: AddGuestForm) => {
    setShowGuestForm(false);
    if (form) {
      const payload: any = {
        businessId: business.businessId,
        accountId: props.user.accountId,
        partySize: form.partySize ? Number(form.partySize) : 1,
        status: VisitStatus.waiting,
        userId: props.user.uid,
        name: form.name
      };
      if (form.quotedTime) {
        payload.quotedTime = Number(form.quotedTime);
      } else {
        payload.quotedTime = 0;
      }
      if (form.phone) {
        payload.phone = form.phone;
      } else {
        if (guestForm) {
          payload.phone = firebase.firestore.FieldValue.delete();
        }
      }
      if (form.service) {
        payload.service = form.service;
      } else {
        if (guestForm) {
          payload.service = firebase.firestore.FieldValue.delete();
        }
      }
      if (form.orderNumber) {
        payload.orderNumber = form.orderNumber;
      } else {
        if (guestForm) {
          payload.orderNumber = firebase.firestore.FieldValue.delete();
        }
      }
      if (form.email) {
        payload.email = form.email;
      } else {
        if (guestForm) {
          payload.email = firebase.firestore.FieldValue.delete();
        }
      }
      if (form.note) {
        payload.note = form.note;
      }
      if (guestForm) {
        if (form.phone !== guestForm.data().phone) {
          payload.errorCode = firebase.firestore.FieldValue.delete();
        }
        saveVisit(payload);
      } else {
        createVisit(payload);
      }
    }
    setGuestForm(null);
  };

  const getPlanName = () => {
    const params = new URLSearchParams(props.location.search);
    const success = params.get("success");
    if (success && success === "pro") {
      return "Pro";
    }
    if (success && success === "premium") {
      return "Premium";
    }
    return "Premium";
  };

  const saveVisit = payload => {
    payload.id = guestForm.id;
    addOrUpdateVisit({ ...guestForm.data(), ...payload });
    logEvent(FIREBASE_EVENTS.visit_update, { partySize: payload.partySize });
  };

  const handleSquareSetupModalClose = () => {
    history.replace("/admin/waitlist");
    setSquareSetupModalConfig(null);
  };

  const hideSuccessAlert = (successAlertProps?: { skip?: boolean }) => {
    const skip = !!successAlertProps?.skip;
    let url = "/admin/waitlist";

    if (skip && isSourceTour) {
      url += "?source=tour&action=video";
    } else if (isVerifyTurnedOn(verificationMode, phoneConfig)) {
      url += "?action=verify";
      const params = new URLSearchParams(props.location.search);
      if (params.get("source") === "tour") {
        url += `&source=${params.get("source")}`;
      }
    }
    history.replace(url);
    setShowSuccessAlert(false);
  };

  const createVisit = payload => {
    payload.checkInTime = new Date();
    payload.initalQueuePosition = waitingVisitors.length + 1;
    addOrUpdateVisit(payload);
    logEvent(FIREBASE_EVENTS.visit_add, { partySize: payload.partySize });
  };
  const handleGuestFormShow = () => setShowGuestForm(true);

  const isAnalyst = props.user.claims.role === "analyst";
  const disabled = isAnalyst;

  const shouldFilterStatBoxes = business => {
    return (
      business?.settings?.advanced?.enableFilteredStatBoxes === undefined ||
      business?.settings?.advanced?.enableFilteredStatBoxes
    );
  };

  return (
    <div className="waitlist main-content">
      {showSuccessAlert && (
        <SweetAlert
          type="success"
          customClass="payment-success-alert"
          confirmBtnText={isVerifyTurnedOn(verificationMode, phoneConfig) ? "Verify my business" : "OK"}
          title={
            isVerifyTurnedOn(verificationMode, phoneConfig)
              ? `Welcome to Waitly ${getPlanName()}`
              : "Payment Successful"
          }
          onConfirm={hideSuccessAlert}
          showCancel={isSourceTour}
          cancelBtnText="Skip for now"
          onCancel={() => hideSuccessAlert({ skip: true })}
        >
          {isSourceTour ? (
            "Let's verify your business and we'll issue you a dedicated toll free phone number. You have 14 days to complete this step."
          ) : (
            <p style={{ fontSize: "20px", color: "#575757" }}>
              {isVerifyTurnedOn(verificationMode, phoneConfig)
                ? "Just one more important step"
                : `Welcome to Waitly ${getPlanName()}!`}
            </p>
          )}
        </SweetAlert>
      )}
      {squareSetupModalConfig && business && squareInfo && (
        <SquareSetupModal
          business={business}
          exitPoint={"squareModal"}
          squareInfo={squareInfo}
          squareSetupModalConfig={squareSetupModalConfig}
          handleSquareSetupModalClose={handleSquareSetupModalClose}
        />
      )}
      <div className="stats-container">
        {!!waitingVisitors && !!allVisitors && !!dashboardStats && (
          <WaitlistStats
            dashboardStats={dashboardStats}
            business={business}
            allVisitors={allVisitors}
            waitingVisitors={waitingVisitors}
            filter={shouldFilterStatBoxes(business) ? waitlistFilter : { partySize: null, category: null }}
          />
        )}
        <div>
          {business && (
            <WaitlistTable
              // @ts-ignore
              user={props.user}
              // @ts-ignore
              messages={messages}
              // @ts-ignore
              business={business}
              // @ts-ignore
              removeVisit={removeVisit}
              // @ts-ignore
              seatUser={seatUser}
              // @ts-ignore
              addNote={addNoteForm}
              // @ts-ignore
              guestList={waitingVisitors}
              // @ts-ignore
              editVisit={editVisit}
              // @ts-ignore
              walkoutVisit={walkoutVisit}
              // @ts-ignore
              sendMessage={sendMessage}
              // @ts-ignore
              notifyGuest={notifyGuest}
              // @ts-ignore
              disabled={disabled}
              filter={waitlistFilter}
              setFilter={setWaitlistFilter}
              listOrder={listOrder}
              setListOrder={setListOrder}
            />
          )}
        </div>
      </div>
      {!disabled && (
        <div className="waitlist__add-guest">
          <CustomIconButton faIcon="plus" handleClick={handleGuestFormShow}></CustomIconButton>
        </div>
      )}
      {!!showGuestForm && !!business && (
        <AddGuestModal
          allVisitors={allVisitors}
          business={business}
          guestForm={guestForm}
          showGuestForm={showGuestForm}
          handleGuestFormClose={handleGuestFormClose}
        />
      )}

      {!!showNoteForm && (
        <AddNoteModal noteForm={noteForm} showModal={showNoteForm} handleFormClose={handleAddNoteFormClose} />
      )}
    </div>
  );
}

export default Waitlist;
